@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap");

* {
    list-style: none;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

body {
    font-family: "Poppins", sans-serif;
    margin: 0;
    padding: 0;
    border: 0;
    overflow-y: scroll !important;
    overflow-x: hidden !important;
}

.main {
    min-height: 100vh;
    font-size: 16px;
    line-height: 1.3;
    color: #495057;
    text-align: center;
    background-color: #ffffff;
}

.container {
    max-width: 1184px;
    margin: 0 auto;
    padding: 0 2px;
    box-shadow: 0 0 7px 1px rgba(0, 0, 0, 0.2);
}

.content {
    padding: 0 0.8rem;

    @media screen and (min-width: 768px) {
        padding: 0 2rem;
    }
}

.logo_pagarpix {
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.border {
    border-bottom: 1px solid #8cb72b;
}

.order {
    max-width: 500px;
    margin: 0 auto;
    margin-top: 30px;
    padding: 15px;
    text-align: left;
    border-radius: 0.25rem;
    background-color: #1b1e20;
    transition: background-color 200ms;

    .display {
        display: flex;
        justify-content: space-between;
        flex-wrap: nowrap;
        gap: 15px;
        cursor: pointer;

        .summary {
            width: 100%;
            display: flex;
            justify-content: space-between;
            flex-wrap: nowrap;
            gap: 10px;
            font-size: 15px;
            overflow: hidden;

            @media screen and (min-width: 451px) {
                font-size: 16px;
            }

            @media screen and (min-width: 1025px) {
                font-size: 17px;
            }

            .info {
                font-weight: 600;
                color: #ffffff;
                white-space: nowrap;
                overflow: hidden;
                transition: color 200ms;

                span {
                    display: none;

                    @media screen and (min-width: 351px) {
                        display: inline-block;
                    }
                }
            }

            .info_open {
                color: #495057;
                transition: color 200ms;
            }

            .price {
                font-weight: 600;
                color: #ffff81;
                white-space: nowrap;
                opacity: 1;
                transition: opacity 200ms;
            }

            .price_open {
                opacity: 0;
                transition: opacity 200ms;
            }
        }

        .arrow {
            width: 18px;
            height: 18px;
            font-size: 18px;
            transition: transform 200ms;

            @media screen and (min-width: 451px) {
                width: 19px;
                height: 19px;
                font-size: 19px;
            }

            @media screen and (min-width: 1025px) {
                width: 20px;
                height: 20px;
                font-size: 20px;
            }

            svg {
                fill: #ffffff;
                transition: fill 200ms;
            }
        }

        .arrow_open {
            transform: rotate(-180deg);
            transition: transform 200ms;

            svg {
                fill: #333333;
                transition: fill 200ms;
            }
        }
    }

    .details {
        max-height: 0;
        overflow: hidden;
        transition: max-height 300ms cubic-bezier(0, 1, 0, 1);

        .about {
            display: flex;
            gap: 10px;
            margin-top: 18px;

            img {
                width: 60px;
                height: 60px;
                border-radius: 0.25rem;

                @media screen and (min-width: 451px) {
                    width: 65px;
                    height: 65px;
                }

                @media screen and (min-width: 1025px) {
                    width: 70px;
                    height: 70px;
                }
            }

            .about_name {
                display: flex;
                align-items: center;
                flex: 1;
                font-size: 15px;
                font-weight: 500;

                @media screen and (min-width: 451px) {
                    font-size: 16px;
                }

                @media screen and (min-width: 1025px) {
                    font-size: 17px;
                }
            }
        }

        .line {
            border-top: 1px solid #cccccc;
            margin: 18px 0;
        }

        .invoice {
            font-size: 13px;
            font-weight: 600;
            padding: 20px;
            border-radius: 0.25rem;
            background-color: #f7f9fc;

            @media screen and (min-width: 451px) {
                font-size: 14px;
            }

            @media screen and (min-width: 1025px) {
                font-size: 15px;
            }

            .table {
                display: flex;
                justify-content: space-between;
                gap: 10px;
                white-space: nowrap;

                span:first-child {
                    overflow: hidden;
                }
            }

            .total {
                display: flex;
                align-items: center;
                color: #44c485;

                span:last-child {
                    font-size: 16px;

                    @media screen and (min-width: 451px) {
                        font-size: 17px;
                    }

                    @media screen and (min-width: 1025px) {
                        font-size: 18px;
                    }
                }
            }
        }
    }

    .details_open {
        max-height: 1000px;
        transition: max-height 300ms cubic-bezier(1, 0, 1, 0);
    }
}

.order_open {
    background-color: #e3e8ed;
    transition: background-color 200ms;
}

.box {
    max-width: 800px;
    margin: 0 auto;
    margin-top: 30px;
    padding: 15px;
    font-size: 14px;
    line-height: 1.6;
    text-align: left;
    border-radius: 0.25rem;
    background-color: #f7f9fc;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);

    @media screen and (min-width: 451px) {
        font-size: 15px;
    }

    @media screen and (min-width: 1025px) {
        font-size: 16px;
    }

    .title {
        font-size: 16px;
        font-weight: 500;
        line-height: 1.5;

        @media screen and (min-width: 451px) {
            font-size: 17px;
        }

        @media screen and (min-width: 1025px) {
            font-size: 18px;
        }
    }
}

.personal {
    // background-color: #ffffff;

    .icon {
        width: 19px;
        height: 19px;
        font-size: 19px;
        margin-right: 5px;
        vertical-align: middle;
        position: relative;
        top: 2px;

        @media screen and (min-width: 451px) {
            width: 20px;
            height: 20px;
            font-size: 20px;
        }

        @media screen and (min-width: 1025px) {
            width: 21px;
            height: 21px;
            font-size: 21px;
        }

        svg {
            fill: #333333;
        }
    }
}

.continue {
    font-size: 14px;
    font-weight: 500;
    color: #333333;
    margin-top: 30px;

    @media screen and (min-width: 451px) {
        font-size: 15px;
    }

    @media screen and (min-width: 1025px) {
        font-size: 16px;
    }
}

.expired {
    color: #ffffff;
    text-align: center;
    background-color: #ff3131;

    .title {
        font-size: 18px;

        @media screen and (min-width: 451px) {
            font-size: 19px;
        }

        @media screen and (min-width: 1025px) {
            font-size: 20px;
        }
    }

    .btn_again {
        font-size: 15px;
        font-weight: 600;
        line-height: 1.3;
        color: #495057;
        padding: 12px 10px;
        border-radius: 0.25rem;
        border: 0;
        background-color: #ffff81;
        cursor: pointer;

        @media screen and (min-width: 451px) {
            font-size: 16px;
            padding: 14px 12px;
        }

        @media screen and (min-width: 1025px) {
            font-size: 17px;
            padding: 16px 14px;
        }
    }
}

.pending {
    font-size: 15px;
    text-align: center;

    @media screen and (min-width: 451px) {
        font-size: 16px;
    }

    @media screen and (min-width: 1025px) {
        font-size: 17px;
    }

    .logo_pix {
        max-width: 120px;
        margin: 10px auto 0;

        @media screen and (min-width: 451px) {
            max-width: 160px;
        }

        @media screen and (min-width: 1025px) {
            max-width: 200px;
        }
    }

    .price_pix {
        font-size: 19px;
        font-weight: 600;
        letter-spacing: 2px;
        margin-top: 15px;
        white-space: nowrap;

        @media screen and (min-width: 451px) {
            font-size: 20px;
        }

        @media screen and (min-width: 1025px) {
            font-size: 21px;
        }
    }

    .qrcode {
        max-width: 250px;
        margin: 20px auto 0;

        @media screen and (min-width: 451px) {
            max-width: 270px;
        }

        @media screen and (min-width: 1025px) {
            max-width: 290px;
        }

        img {
            max-width: 100%;
            height: auto;
            -webkit-box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
            box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
        }
    }

    .copy {
        margin-top: 15px;

        .btn_copy {
            font-size: 18px;
            font-weight: 500;
            color: #44c485;
            border: none;
            background-color: transparent;
            cursor: pointer;

            @media screen and (min-width: 451px) {
                font-size: 19px;
            }

            @media screen and (min-width: 1025px) {
                font-size: 20px;
            }

            svg {
                margin-top: -5px;
                margin-right: 4px;
                vertical-align: middle;
            }
        }

        .copied {
            color: #44c485;
        }
    }

    .waiting {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 6px;
        font-size: 19px;
        margin-top: 15px;
        white-space: nowrap;

        @media screen and (min-width: 451px) {
            font-size: 20px;
        }

        @media screen and (min-width: 1025px) {
            font-size: 21px;
        }

        @media screen and (max-width: 365px) {
            span > span {
                display: none;
            }
        }

        svg {
            font-size: 30px;
            color: #44c485;
            margin-left: -4px;
            -webkit-animation: rotate linear 1000ms infinite;
            -moz-animation: rotate linear 1000ms infinite;
            -ms-animation: rotate linear 1000ms infinite;
            -o-animation: rotate linear 1000ms infinite;
            animation: rotate linear 1000ms infinite;

            @media screen and (min-width: 451px) {
                font-size: 34px;
            }

            @media screen and (min-width: 1025px) {
                font-size: 38px;
            }
        }
    }

    .timer {
        font-size: 19px;
        font-weight: 600;
        letter-spacing: 2px;
        color: #000000;
        margin-top: 15px;

        @media screen and (min-width: 451px) {
            font-size: 20px;
        }

        @media screen and (min-width: 1025px) {
            font-size: 21px;
        }
    }
}

.help {
    font-size: 15px;

    @media screen and (min-width: 451px) {
        font-size: 16px;
    }

    @media screen and (min-width: 1025px) {
        font-size: 17px;
    }

    .item {
        display: flex;
        gap: 15px;
        margin-top: 15px;

        .number {
            width: 30px;
            font-size: 14px;
            font-weight: 600;
            color: #ffffff;

            @media screen and (min-width: 451px) {
                width: 33px;
                font-size: 15px;
            }

            @media screen and (min-width: 1025px) {
                width: 36px;
                font-size: 16px;
            }

            .rounded {
                width: 30px;
                height: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                background-color: #44c485;

                @media screen and (min-width: 451px) {
                    width: 33px;
                    height: 33px;
                }

                @media screen and (min-width: 1025px) {
                    width: 36px;
                    height: 36px;
                }
            }
        }

        .step {
            display: flex;
            align-items: center;
            flex: 1;
        }
    }

    .help_bold {
        font-weight: 600;
    }
}

.footer {
    font-size: 12px;
    line-height: 1.5;
    color: #666666;
    margin-top: 40px;
    padding: 25px 0;
    background-color: #1b1e20;
}

.paid {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;
    min-height: 100vh;
    font-size: 25px;
    font-weight: 500;
    line-height: 1.5;
    color: #ffffff;
    text-align: center;
    padding: 20px;
    background-color: #44c485;

    @media screen and (min-width: 451px) {
        font-size: 30px;
    }

    @media screen and (min-width: 1025px) {
        font-size: 35px;
    }

    .check {
        width: 120px;
        height: 120px;
        font-size: 120px;
        display: inline-flex;

        @media screen and (min-width: 451px) {
            width: 130px;
            height: 130px;
            font-size: 130px;
        }

        @media screen and (min-width: 1025px) {
            width: 140px;
            height: 140px;
            font-size: 140px;
        }

        svg {
            fill: #ffffff;
        }
    }

    .btn_go {
        display: inline-flex;
        font-size: 16px;
        font-weight: 600;
        line-height: 1.3;
        color: #ffffff;
        padding: 12px 20px;
        border-radius: 0.25rem;
        text-decoration: none;
        background-color: #1082be;

        @media screen and (min-width: 451px) {
            font-size: 17px;
            padding: 14px 24px;
        }

        @media screen and (min-width: 1025px) {
            font-size: 18px;
            padding: 16px 28px;
        }
    }
}

.error {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 15px;
    min-height: 100vh;
    font-size: 17px;
    font-weight: 500;
    line-height: 1.4;
    color: #495057;
    text-align: center;
    padding: 20px;
    background-color: #ffffff;

    @media screen and (min-width: 451px) {
        font-size: 18px;
    }

    @media screen and (min-width: 1025px) {
        font-size: 19px;
    }
}

.hide {
    display: none;
}

.nowrap {
    white-space: nowrap;
}

.image_responsive {
    max-width: 100%;
    height: auto;
}

.swal_icon {
    font-size: 12px !important;

    @media screen and (min-width: 451px) {
        font-size: 13px !important;
    }

    @media screen and (min-width: 1025px) {
        font-size: 14px !important;
    }
}

.swal_title {
    font: 22px "Poppins", sans-serif !important;
    font-weight: 600 !important;

    @media screen and (min-width: 451px) {
        font-size: 25px !important;
    }

    @media screen and (min-width: 1025px) {
        font-size: 28px !important;
    }
}

.swal_text {
    font: 16px "Poppins", sans-serif !important;
    line-height: 1.4 !important;

    @media screen and (min-width: 451px) {
        font-size: 17px !important;
    }

    @media screen and (min-width: 1025px) {
        font-size: 18px !important;
    }
}

.swal_button {
    font: 15px "Poppins", sans-serif !important;
    background-color: #000000 !important;

    @media screen and (min-width: 451px) {
        font-size: 16px !important;
    }

    @media screen and (min-width: 1025px) {
        font-size: 17px !important;
    }
}

.page_loading {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    font: 19px "Poppins", sans-serif;
    text-align: center;
    line-height: 1.4;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;

    @media screen and (min-width: 451px) {
        font-size: 22px;
    }

    @media screen and (min-width: 1025px) {
        font-size: 25px;
    }

    span {
        width: 50px;
        height: 50px;
        border-width: 4px;
        border-style: solid;

        @media screen and (min-width: 451px) {
            width: 70px;
            height: 70px;
        }

        @media screen and (min-width: 1025px) {
            width: 100px;
            height: 100px;
        }
    }
}

.section_loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 20px 20px;

    span {
        width: 30px;
        height: 30px;
        border-width: 2px;
        border-style: solid;

        @media screen and (min-width: 451px) {
            width: 50px;
            height: 50px;
        }

        @media screen and (min-width: 1025px) {
            width: 80px;
            height: 80px;
        }
    }
}

.mt_10 {
    margin-top: 10px;
}

.mt_12 {
    margin-top: 12px;
}

.mt_15 {
    margin-top: 15px;
}

.mt_18 {
    margin-top: 18px;
}

.mt_20 {
    margin-top: 20px;
}

@-webkit-keyframes rotate {
    0% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(360deg);
    }
}
@-moz-keyframes rotate {
    0% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(360deg);
    }
}
@-ms-keyframes rotate {
    0% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(360deg);
    }
}
@-o-keyframes rotate {
    0% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(360deg);
    }
}
@keyframes rotate {
    0% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(360deg);
    }
}